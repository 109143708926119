
import { defineComponent, onMounted, onUpdated } from "vue";
import { ToggleComponent } from "../../assets/ts/components/_ToggleComponent";
import ProjectMenu from "@/components/pages/project/layout/MenuProgramList.vue";
import { asideTheme } from "../../core/helpers/config";

export default defineComponent({
  name: "layout-aside-project-list",
  components: {
    ProjectMenu,
  },
  props: {
    lightLogo: String,
    darkLogo: String,
  },
  setup() {
    onMounted(() => {
      ToggleComponent.reinitialization();
    });

    onUpdated(() => {
      ToggleComponent.bootstrap();
    });

    return {
      asideTheme,
    };
  },
});

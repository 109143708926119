
import { defineComponent, onMounted, computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "components-pages-project-layout-menu-list",
  components: {},
  setup() {
    const store = useStore();
    const router = useRouter();
    const rStatus = ref([
      {
        name: "Informasi Aset",
        url: "informasi-assets",
      },
      {
        name: "Lokasi",
        url: "location",
      },
      {
        name: "Data Perolehan Aset",
        url: "aset-own",
      },
      {
        name: "Sertifikat/Data Kepemilikan",
        url: "sertifikat",
      },
      {
        name: "Data Administrasi Berjalan",
        url: "data-admin",
      },
      {
        name: "Data Nilai Aset",
        url: "data-nilai",
      },
    ]);
    const dataMenuAside = ref("");
    const detectId = ref("")

    onMounted(() => {
      const urlParams = new URLSearchParams(location.search);
      for (const [key, value] of urlParams) {
          console.log(`${key}:${value}`);
          detectId.value = value
      }
    });

    return {
      rStatus,
      dataMenuAside,
    };
  },
});
